.navbar {
  background-color: #000000;
  border-bottom: 1px solid #FF0000;
  padding: 0px 20px;
  z-index: 1000;
}

.navbar-brand > img {
  height: 85px;
  width: auto;
}

.navbar-nav .nav-link {
  color: #FFFFFF !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (min-width: 1200px) {
  .navbar-nav .nav-link {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
}

.navbar-nav .nav-link.active {
  color: #808080 !important;
}


.navbar-nav .nav-link:hover {
  color: #808080 !important;
}